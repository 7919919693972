@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #0c0c0c;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif;
}

.top-bar {
  display: flex;
  min-height: 90px;
  background: #0c0c0c;
  color: #ebebeb;
  align-items: center;
  flex-wrap: wrap;
}
.top-bar__nav-toggle {
  margin: 0px 100px;
  display: inline-block;
  color: #ebebeb;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 50px;
  min-width: 55px;
  text-align: center;
  transition: 0.25s;
}
.top-bar__nav-toggle:focus {
  outline: none;
  transform: scale(1.1);
}
.top-bar__brand {
  color: #ebebeb;
  text-decoration: none;
  font-size: 50px;
  text-transform: uppercase;
  text-align: center;
  transition: 0.25s;
}
.top-bar__contact {
  margin-left: auto;
}
.top-bar__contact a {
  color: #ebebeb;
  text-decoration: none;
  font-size: 20px;
  margin-right: 30px;
}
.top-bar__social {
  display: flex;
  height: 90px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 110px;
  transition: 0.25s;
}
.top-bar__social a {
  color: #ebebeb;
  font-size: 35px;
}
@media (max-width: 1000px) {
  .top-bar__nav-toggle {
    margin-right: 0;
  }
  .top-bar__brand {
    flex: 1;
  }
  .top-bar__contact {
    display: none;
  }
  .top-bar__social {
    margin-left: auto;
  }
}
@media (max-width: 700px) {
  .top-bar__nav-toggle {
    margin-left: 20px;
  }
  .top-bar__social {
    margin-right: 20px;
  }
}
.top-bar__nav {
  width: 100%;
  background: #0c0c0c;
  transition: 0.25s;
  max-height: 400px;
  overflow: hidden;
}
.top-bar__nav.collapsed {
  max-height: 0;
  min-height: 0;
}
.top-bar__nav-list {
  list-style: none;
  padding: 0;
}
.top-bar__nav-list li {
  padding: 1rem;
  text-align: center;
}
.top-bar__nav-list a {
  color: #ebebeb;
  text-decoration: none;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.4rem;
  transition: 0.25s;
  border-bottom: 2px solid transparent;
}
.whiteThemeBg {
  background-color: rgba(255, 255, 255, 0.9);
}
.top-bar__nav-list a:hover {
  transform: scale(1.2);
  filter: brightness(140%);
  border-bottom: 2px solid rgb(217, 179, 70);
}

.hamburger {
  position: relative;
  height: 35px;
}
.hamburger span {
  width: 35px;
  height: 7px;
  position: absolute;
  background: #ebebeb;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 3px;
}
.hamburger span:nth-child(1) {
  top: 20%;
  transition: top 0.125s 0.25s, transform 0.125s;
}
.hamburger span:nth-child(2) {
  top: 50%;
  transition: top 0.125s 0.25s, transform 0.125s;
}
.hamburger span:nth-child(3) {
  top: 80%;
  transition: top 0.125s 0.25s, transform 0.125s;
}
.hamburger.closed span:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: top 0.125s, transform 0.125s 0.25s;
}
.hamburger.closed span:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: top 0.125s, transform 0.125s 0.25s;
}
.hamburger.closed span:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: top 0.125s, transform 0.125s 0.25s;
}

.contact-form {
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 400px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input,
.form-text-area {
  background-color: #f0f4f5;
  height: 50px;
  padding-left: 16px;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control:focus {
  border-color: rgb(217, 179, 70);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgb(217, 179, 70);
}

.contact-form .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.contact-form .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
}
.carousel-inner .carousel-item {
  min-height: 400px;
  max-height: 400px;
  /* width: 100vw; */
  /* min-width: 60vw; */
  /* max-width: 60vw; */
}
.contact-form .submit-button-wrapper {
  text-align: center;
}

.contact-form .submit-button-wrapper input {
  border: none;
  border-radius: 4px;
  background-color: rgb(217, 179, 70);
  color: white;
  text-transform: uppercase;
  padding: 10px 60px;
  font-weight: 500;
  letter-spacing: 2px;
}

.contact-form .submit-button-wrapper input:hover {
  background-color: rgb(217, 179, 70);
}

.carousel {
  position: relative;
}
.carousel-item img {
  object-fit: contain;
}
#carousel-thumbs {
  width: 100vw;
  /* background: rgba(255, 255, 255, 0.3); */
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#carousel-thumbs img {
  border: 5px solid transparent;
  cursor: pointer;
}
#carousel-thumbs img:hover {
  border-color: rgba(255, 255, 255, 0.3);
}
#carousel-thumbs .selected img {
  border-color: #fff;
}
.carousel-control-prev,
.carousel-control-next {
  width: 50px;
}

.carousel-container #carousel-thumbs img {
  border-width: 3px;
}

.carousel-container #carousel-thumbs {
  background: #ccccce;
}
