.grid--container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
}

.grid--image {
  background-size: cover;
  background-position: center;
  grid-column: 1/7;
  grid-row: 1/7;
}

.grid--content {
  background-color: rgba(255, 255, 255, 0.8);
  grid-column: 6/-1;
  grid-row: 4/-1;
}

.card--title {
  font-family: "Blinker", sans-serif;
}

button {
  border: 1px solid black;
}
button:hover {
  background: #000;
  color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1000px) {
  .grid-container {
    height: 100%;
  }

  .grid--content {
    background-color: rgba(255, 255, 255, 0.9);
    grid-column: 5 / span 6;
  }
}
@media (max-width: 768px) {
  .grid--image {
    grid-column: 1/-1;
  }

  .grid--content {
    background-color: rgba(255, 255, 255, 0.9);
    grid-column: 2 / span 8;
    grid-row: 6/-1;
    min-height: 100%;
  }
}
@media (max-width: 500px) {
  .grid--container {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
  }

  .grid--image {
    grid-column: 1/-1;
    grid-row: 1 / span 4;
  }

  .grid--content {
    background-color: white;
    grid-column: 1/-1;
    grid-row: 5/-1;
    min-height: 100%;
  }
}

.slider-wrapper {
  width: 760px;
  margin: auto;
}
.slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}

.scroll-down span:nth-child(2) {
  animation-delay: 4.8s;
}
.scroll-down span:nth-child(3) {
  animation-delay: 4.6s;
}
.scroll-down span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
  animation-delay: 5s;
  opacity: 0;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
